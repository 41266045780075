import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"
import ResponsivePlayer from "components/ResponsivePlayer.js"
import { Section, Container } from "../components/Layouts"

function CoverageReel({ data }) {
  const page = data.allUniquePagesJson.edges[0].node

  return (
    <Layout language="en">
      <SEO
        title={page.metaTitle}
        description={page.metaDescription}
        robots="noindex"
        noGtag
      />

      <Section>
        <Container>
          <Link className="back-link mb-2" to="/" style={{ display: "block" }}>
            BACK
          </Link>

          <h1 style={{ fontSize: "3rem" }}>{page.heading}</h1>

          {page.wistiaVideos.map(video => (
            <>
              <ResponsivePlayer
                key={video.url}
                url={video.url}
                style={{ marginBottom: "3rem" }}
              />
            </>
          ))}
        </Container>
      </Section>
    </Layout>
  )
}

export default CoverageReel

export const pageQuery = graphql`
  query reelQuery {
    allUniquePagesJson(filter: { title: { eq: "/covidcoverage/" } }) {
      edges {
        node {
          metaDescription
          metaTitle
          name
          title
          heading
          wistiaVideos {
            title
            url
          }
        }
      }
    }
  }
`
